<template>
  <div>
    <c-card title="LBLDETAIL" class="cardClassDetailForm fm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-3 col-lg-3">
          <!-- 작성부서/이름/일 -->
          <c-label-text title="LBL0001237" :value="request"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-1">
          <!-- 사업장 -->
          <c-label-text title="LBLPLANT" :value="assessPlan.plantName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-2">
          <!-- 평가기간 -->
          <c-label-text title="LBL0000607" :value="assessPlan.assessmentStartDate + '~' + assessPlan.assessmentEndDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-1">
          <!-- 평가년도 -->
          <c-label-text title="LBL0001000" :value="assessPlan.assessmentYear"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-1">
          <!-- 정기/수시 -->
          <c-label-text title="LBL0001238" :value="assessPlan.ramAssessTypeName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-1">
          <!-- Matrix -->
          <c-label-text title="LBL0001239" :value="assessPlan.matrixName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-3">
          <!-- 평가명 -->
          <c-label-text title="LBL0000367" :value="assessPlan.assessmentName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-9 col-lg-9" >
          <!-- 상세내용 -->
          <c-label-text title="LBL0001056" :value="assessPlan.remark"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 fmInfo">
          <c-upload 
            :editable="false"
            :attachInfo="{
              isSubmit: true,
              taskClassCd: 'RISK_PLAN',
              taskKey: assessPlan.ramRiskAssessmentPlanId,
            }"
          ></c-upload>
        </div>
      </template>
    </c-card>
    <!-- 평가팀&관련자료 -->
    <c-card title="평가팀" class="q-mt-sm" :noMarginPadding="true">
      <template slot="card-detail">
        <div class="col-12 q-mt-xs">
          <q-chip
            v-for="(item, idx) in assessPlan.teams"
            outline square
            :key="idx"
            color="primary"
            text-color="white"
            icon="person_outline"
            :label="setTeamLabel(item)"
            :title="setTeamLabel(item)"
            @click="truncate = !truncate"
          >
          </q-chip>
        </div>
        <div class="col-12">
          <c-table
            class="q-mt-sm"
            ref="table3"
            title="관련자료"
            :columns="grid3.columns"
            :gridHeight="grid3.height"
            :data="assessPlan.relatedDocuments"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="false"
          >
          </c-table>
        </div>
      </template>
    </c-card>
    <!-- 회의 목록 -->
    <c-table
      class="q-mt-sm"
      ref="table2"
      title="LBLLISTMEETING"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="assessPlan.conferences"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fm-plan-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        plantName: '',  // 사업장명
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/4m/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        ramAssessTypeName: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: '',  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regUserDeptName: '',
        regDt: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
      grid: {
        columns: [
          {
            name: 'conferenceName',
            field: 'conferenceName',
            // 회의명
            label: 'LBL0000620',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'conferenceDate',
            field: 'conferenceDate',
            // 회의일
            label: 'LBL0000621',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'conferenceContents',
            field: 'conferenceContents',
            // 회의내용
            label: 'LBL0000622',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'attendees',
            field: 'attendees',
            // 참석자
            label: 'LBL0000623',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            // 첨부파일
            label: 'LBLATTACHMENT',
            align: 'center',
            style: 'width:100px',
            type: 'attach',
            taskClassCd: 'RSA_CONFERENCE',
            keyText: 'ramAssessmentConferenceId',
            sortable: false,
          },
        ],
        data: [],
        height: 'auto' //'300px'
      },
      grid3: {
        columns: [
          {
            name: 'ramDocumentTypeName',
            field: 'ramDocumentTypeName',
            // 분류
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'documentTitle',
            field: 'documentTitle',
            description: 'documentSubTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 자료명
            label: 'LBL0000625',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            // 첨부파일
            label: 'LBLATTACHMENT',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'RSA_RELATE_INFO',
            keyText: 'ramRelatedDocumentId',
            sortable: false,
          },
        ],
        height: 'auto' //'450px'
      },
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    request() {
      return this.assessPlan.regUserDeptName + ' / ' + this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt.slice(0, 10)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.ram.assessPlan.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.assessPlan, _result.data);
        this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
          [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []

        this.$emit('setAssessmentName', this.assessPlan.assessmentName)
      },);
    },
    setTeamLabel(item) {
      let role = item.ramRoleTypeCd !== 'RRT0000010' ? item.ramRoleTypeName : item.ramRoleTypeName + '(' + item.etcRole + ')'
      return role + ' / ' + item.ramInsideOutsideName + ' / ' + item.userName + (item.deptName ? ' / ' + item.deptName : '')
    }
  }
};
</script>
<style lang="sass">
.fmInfo
  .upload-cover
    margin: 0 !important
    height: auto !important
    .nofileArea
      height: auto !important
.fm 
  .q-pa-sm
    padding: 0 15px 15px 15px !important
</style>